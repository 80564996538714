import { type MetaArgs, Outlet } from '@remix-run/react'

import { Header } from '~/components/Header'
import { getSocialMetas } from '~/utils/utils'

export function meta({ location }: MetaArgs) {
  return [
    ...getSocialMetas({
      url: `https://buy.loungepair.com${location.pathname}`,
    }),
  ]
}

export const handle = {
  getSitemapEntries: () => {
    return null
  },
}

export default function Directory() {
  return (
    <div className="sm:bg-midnight-100 sm:pb-12">
      <Header />
      <div className="max-w-screen-sm lg:max-w-[792px] sm:px-14 pb-8 sm:pt-8 sm:mt-10 mx-auto bg-white sm:rounded-3xl">
        <h1 className="mb-1 p-6 font-bold text-h1">
          Buy access to over 900+ airport lounges
        </h1>
        <Outlet />
      </div>
    </div>
  )
}
