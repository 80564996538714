import { Slot } from '@radix-ui/react-slot'
import { type VariantProps, cva } from 'class-variance-authority'
import clsx from 'clsx'
import * as React from 'react'

const buttonVariants = cva(
  'inline-flex items-center justify-center gap-2 whitespace-nowrap text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-blue-400 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 dark:ring-offset-midnight-950 dark:focus-visible:ring-midnight-300',
  {
    variants: {
      variant: {
        default: 'bg-blue-500 text-white hover:bg-blue-500/90',
        dark: 'bg-midnight-900 text-midnight-50 hover:bg-midnight-900/90 dark:bg-midnight-50 dark:text-midnight-900 dark:hover:bg-midnight-50/90',
        destructive:
          'bg-red-500 text-midnight-50 hover:bg-red-500/90 dark:bg-red-900 dark:text-midnight-50 dark:hover:bg-red-900/90',
        outline:
          'border border-midnight-200 bg-white hover:bg-midnight-100 hover:text-midnight-900 dark:border-midnight-800 dark:bg-midnight-950 dark:hover:bg-midnight-800 dark:hover:text-midnight-50',
        secondary:
          'bg-midnight-100 text-midnight-900 hover:bg-midnight-100/80 dark:bg-midnight-800 dark:text-midnight-50 dark:hover:bg-midnight-800/80',
        ghost:
          'hover:bg-midnight-100 hover:text-midnight-900 dark:hover:bg-midnight-800 dark:hover:text-midnight-50',
        link: 'text-midnight-900 underline-offset-4 hover:underline dark:text-midnight-50',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-8 py-1 px-4 text-xs',
        lg: 'h-11 px-8',
        icon: 'h-10 w-10',
      },
      rounded: {
        default: 'rounded-md',
        full: 'rounded-full',
        none: 'rounded-none',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
      rounded: 'default',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, rounded, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={clsx(buttonVariants({ variant, size, rounded, className }))}
        ref={ref}
        {...props}
      />
    )
  },
)
Button.displayName = 'Button'

export { Button, buttonVariants }
