import { type LoaderFunctionArgs, data } from '@remix-run/node'
import clsx from 'clsx'

import { Link, useLoaderData } from '@remix-run/react'

import { UseBuyOnLoungePair } from '~/components/Mark'
import { getLoungesDirectoryCached } from '~/models/lounges.server'

import {
  type SupportedCurrencies,
  getCurrencyConversions,
} from '~/models/currencies.server'
import { getAccountSession } from '~/sessions.server'
import { getServerTiming, headersWithTiming } from '~/timing.server'
import { httpsInProduction } from '~/utils/utils.server'

export const headers = headersWithTiming(({ loaderHeaders }) => ({
  Link: loaderHeaders.get('Link')!,
  Vary: 'Cookie',
  'Cache-Control':
    'public, max-age=300, s-maxage=3600, stale-while-revalidate=86400, stale-if-error=86400',
}))

export const handle = {
  features: { footer: false },
  getSitemapEntries: () => {
    return {
      route: '/directory/',
      priority: 0.7,
    }
  },
}

interface MinimalAirport {
  iata: string
  name: string
  lounges: {
    slug: string
    name: string
    location: string | null
    hasLoungePair: boolean
    offer: string
    lowestOffer?: {
      price: number | null
      currency: string
    }
  }[]
}

export async function loader(args: LoaderFunctionArgs) {
  const { time, getServerTimingHeader } = getServerTiming()
  const accountSession = await getAccountSession(args.request)
  if (accountSession instanceof Error) throw accountSession
  const account = await accountSession.getSession(
    args.request.headers.get('Cookie'),
  )
  const hasCurrency =
    account.has('user') && typeof account.get('user')?.currency === 'string'

  const airports = await time(
    'getLoungesDirectoryCached_index',
    getLoungesDirectoryCached(args).then((r) =>
      r.airports.map(
        (a) =>
          ({
            iata: a.iata,
            name: a.name,
            lounges: a.lounges.map((l) => ({
              slug: l.slug,
              name: l.name,
              location: l.location,
              hasLoungePair: l.hasLoungePair,
              offer: '',
              lowestOffer: l.lowestOffer
                ? {
                    price: l.lowestOffer.price,
                    currency: l.lowestOffer.currency,
                  }
                : undefined,
            })),
          }) satisfies MinimalAirport,
      ),
    ),
  )

  if (hasCurrency) {
    const accountCurrency = account.get('user')?.currency as SupportedCurrencies
    const [USD, EUR, INR, GBP] = await Promise.all([
      getCurrencyConversions('USD'),
      getCurrencyConversions('EUR'),
      getCurrencyConversions('INR'),
      getCurrencyConversions('GBP'),
    ])

    for (const airport of airports) {
      for (const lounge of airport.lounges) {
        if (lounge.lowestOffer?.price) {
          switch (lounge.lowestOffer.currency) {
            case 'USD': {
              const rate = USD.get(accountCurrency)
              if (rate) {
                lounge.offer = Intl.NumberFormat('default', {
                  currency: accountCurrency,
                  style: 'currency',
                }).format(Math.ceil(lounge.lowestOffer.price * rate))
                delete lounge.lowestOffer
              }

              continue
            }
            case 'EUR': {
              const rate = EUR.get(accountCurrency)
              if (rate) {
                lounge.offer = Intl.NumberFormat(undefined, {
                  currency: accountCurrency,
                  style: 'currency',
                }).format(Math.ceil(lounge.lowestOffer.price * rate))
                delete lounge.lowestOffer
              }
              continue
            }
            case 'INR': {
              const rate = INR.get(accountCurrency)
              if (rate) {
                lounge.offer = Intl.NumberFormat(undefined, {
                  currency: accountCurrency,
                  style: 'currency',
                }).format(Math.ceil(lounge.lowestOffer.price * rate))
                delete lounge.lowestOffer
              }
              continue
            }
            case 'GBP': {
              const rate = GBP.get(accountCurrency)
              if (rate) {
                lounge.offer = Intl.NumberFormat(undefined, {
                  currency: accountCurrency,
                  style: 'currency',
                }).format(Math.ceil(lounge.lowestOffer.price * rate))
                delete lounge.lowestOffer
              }
              continue
            }
          }
        }
      }
    }
  } else {
    for (const airport of airports) {
      for (const lounge of airport.lounges) {
        if (lounge.lowestOffer?.price) {
          lounge.offer = Intl.NumberFormat(undefined, {
            currency: lounge.lowestOffer.currency,
            style: 'currency',
          }).format(lounge.lowestOffer.price)
          delete lounge.lowestOffer
        }
      }
    }
  }

  const canonical = httpsInProduction(new URL(args.request.url))
  canonical.pathname = '/directory/'
  canonical.search = ''

  return data(
    { airports },
    {
      headers: getServerTimingHeader({
        Link: `<${canonical}>; rel="canonical"`,
      }),
    },
  )
}

export default function Home() {
  const { airports } = useLoaderData<typeof loader>()

  return (
    <div className="max-w-3xl mx-auto">
      <nav aria-label="Directory" className="directory">
        {airports.map((airport) => (
          <AirportWithLounges key={airport.iata} airport={airport} />
        ))}
      </nav>
    </div>
  )
}

function AirportWithLounges({
  airport,
}: React.PropsWithoutRef<{
  airport: MinimalAirport
}>) {
  return (
    <div>
      {airport.iata ? (
        <Link
          to={`/at/${airport.iata}/`}
          state={{ from: 'routes/directory/_index/_directory' }}
          className="link"
        >
          <h3>
            {airport.name}
            <span className="iata">({airport.iata})</span>
          </h3>
        </Link>
      ) : null}
      <ul className="lounges">
        {airport.lounges?.map((lounge) => (
          <li key={lounge.slug}>
            <Link
              to={`/at/${airport.iata}/${encodeURIComponent(lounge.slug)}/`}
              state={{ from: 'routes/directory/_index/_directory' }}
              className="focus:outline-none"
            >
              {lounge.hasLoungePair ? <UseBuyOnLoungePair /> : null}
              <span className="name">{lounge.name}</span>

              {lounge.offer ? (
                <span
                  className={clsx(lounge.hasLoungePair ? 'offer lp' : 'offer')}
                >
                  {lounge.offer}
                </span>
              ) : null}

              {lounge.location ? (
                <span className="location">{lounge.location}</span>
              ) : null}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
