import type { ComponentPropsWithRef } from 'react'

export default function LoungeIcon(
  props: ComponentPropsWithRef<'svg'> & {
    alt?: string
  },
) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g clipPath="url(#clip0_698_997)">
        <path
          d="M16.8011 5.79972H7.20262C5.54538 5.79972 4.20309 7.14201 4.20309 8.79925V10.299C3.74192 10.1078 3.23574 9.99906 2.70333 9.99906C2.60209 9.99906 2.50086 10.0028 2.40337 10.0103V8.79925C2.40337 6.14841 4.55179 4 7.20262 4H16.8011C19.452 4 21.6004 6.14841 21.6004 8.79925V10.0103C21.5029 10.0028 21.4017 9.99906 21.3004 9.99906C20.768 9.99906 20.2618 10.104 19.8007 10.299V8.79925C19.8007 7.14201 18.4584 5.79972 16.8011 5.79972ZM21.3004 11.1989C21.4017 11.1989 21.5029 11.2026 21.6004 11.2139C22.9502 11.3639 24 12.5074 24 13.8985V18.9977C24 19.9913 23.1939 20.7974 22.2003 20.7974H20.4006C19.6169 20.7974 18.9495 20.295 18.7021 19.5976H5.29792C5.05046 20.295 4.38307 20.7974 3.59944 20.7974H1.79972C0.806124 20.7974 0 19.9913 0 18.9977L0.00374941 13.8985C0.00374941 12.5074 1.05359 11.3639 2.40337 11.2139C2.50086 11.2026 2.60209 11.1989 2.70333 11.1989C3.27324 11.1989 3.80566 11.3751 4.24059 11.6788C4.86674 12.1137 5.29792 12.8036 5.38416 13.5985H18.6158C18.7021 12.8036 19.137 12.1137 19.7594 11.6788C20.1943 11.3751 20.7268 11.1989 21.2967 11.1989H21.3004ZM20.4006 13.8985V15.3982V17.7978V18.9977H22.2003V13.8985C22.2003 13.3998 21.7991 12.9986 21.3004 12.9986C20.8017 12.9986 20.4006 13.3998 20.4006 13.8985ZM18.6008 17.7978V15.3982H5.40291V17.7978H18.6008ZM3.60319 17.7978V15.3982V13.8985C3.60319 13.3998 3.202 12.9986 2.70333 12.9986C2.20466 12.9986 1.80347 13.3998 1.80347 13.8985V18.9977H3.60319V17.7978Z"
          fill="#374D63"
        />
      </g>
      <defs>
        <clipPath id="clip0_698_997">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
