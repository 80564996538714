import clsx from 'clsx'
import type { ComponentPropsWithRef } from 'react'

export default function PayRewardsLogo(
  props: ComponentPropsWithRef<'svg'> & { alt?: string; dark?: boolean },
) {
  return (
    <svg
      viewBox="0 0 173 35"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <title>pay.com.au Logo</title>
      <g fill="#2a65c0">
        <path d="M83.1530612,22.8709016 C82.3281322,22.8709016 81.6530612,22.1958862 81.6530612,21.370799 C81.6530612,20.545917 82.3281322,19.8709016 83.1530612,19.8709016 C83.9779902,19.8709016 84.6530612,20.545917 84.6530612,21.370799 C84.6530612,22.1958862 83.9779902,22.8709016 83.1530612,22.8709016" />
        <path d="M142.153061,22.8709016 C141.328041,22.8709016 140.653061,22.1958862 140.653061,21.370799 C140.653061,20.545917 141.328041,19.8709016 142.153061,19.8709016 C142.978082,19.8709016 143.653061,20.545917 143.653061,21.370799 C143.653061,22.1958862 142.978082,22.8709016 142.153061,22.8709016" />
        <path d="M34.6530612,28.7997568 C34.6530612,32.1524716 31.9352013,34.8709016 28.5816243,34.8709016 L6.72309634,34.8709016 C3.37022024,34.8709016 0.653061224,32.1524716 0.653061224,28.7997568 L0.653061224,6.94134566 C0.653061224,3.58886445 3.37022024,0.870901639 6.72309634,0.870901639 L28.5816243,0.870901639 C31.9352013,0.870901639 34.6530612,3.58886445 34.6530612,6.94134566 L34.6530612,28.7997568 Z" />
      </g>
      <g fill="#2a65c0">
        <path d="M154.148363,17.1514866 C153.515304,16.8478366 152.688072,16.6452448 151.78857,16.6452448 C150.205006,16.6452448 149.231174,17.3024793 149.231174,18.517317 L149.231174,18.567965 C149.231174,19.605416 150.057491,20.2129537 151.251567,20.2129537 C152.979444,20.2129537 154.148363,19.2261507 154.148363,17.8351152 L154.148363,17.1514866 Z M151.835912,8.90062462 C155.877155,8.90062462 157.653061,11.0768225 157.653061,14.7467785 L157.653061,22.6176618 L154.075635,22.6176618 L154.075635,21.1505355 C153.173616,22.1882242 151.932655,22.8709016 150.131363,22.8709016 C147.673226,22.8709016 145.653061,21.4032997 145.653061,18.7203845 L145.653061,18.6697365 C145.653061,15.7086142 147.817768,14.3418326 150.90988,14.3418326 C152.226314,14.3418326 153.173616,14.5698673 154.099649,14.8989601 L154.099649,14.6709254 C154.099649,13.0761091 153.149145,12.1910776 151.300281,12.1910776 L147.600268,12.1910776 L147.600268,8.87090164 L151.835912,8.90062462 Z" />
        <path d="M49.8730028,15.9334832 C49.8730028,13.6644971 48.3813049,12.1602247 46.6146299,12.1602247 C44.8495902,12.1602247 43.3826566,13.6644971 43.3826566,15.9334832 L43.3826566,15.9849911 C43.3826566,18.2534981 44.8495902,19.7577704 46.6146299,19.7577704 C48.3813049,19.7577704 49.8730028,18.2791323 49.8730028,15.9849911 L49.8730028,15.9334832 Z M47.5841751,23.0475727 C45.5700908,23.0475727 44.3276712,22.1038987 43.4319515,21.0071382 L43.4319515,26.8709016 L39.6530612,26.8709016 L39.6530612,9.12484792 L43.4319515,9.12484792 L43.4319515,11.0881402 C44.3522018,9.81409646 45.6193858,8.87090164 47.5841751,8.87090164 C50.6939624,8.87090164 53.6530612,11.3689185 53.6530612,15.9334832 L53.6530612,15.9849911 C53.6530612,20.5490766 50.7423228,23.0475727 47.5841751,23.0475727 L47.5841751,23.0475727 Z" />
        <path d="M64.1482964,17.1514866 C63.5152248,16.8478366 62.6884352,16.6452448 61.788458,16.6452448 C60.2048642,16.6452448 59.2312422,17.3024793 59.2312422,18.517317 L59.2312422,18.567965 C59.2312422,19.605416 60.0573457,20.2129537 61.251445,20.2129537 C62.9798128,20.2129537 64.1482964,19.2261507 64.1482964,17.8351152 L64.1482964,17.1514866 Z M61.8358012,8.90062462 C65.877579,8.90062462 67.6530612,11.0768225 67.6530612,14.7467785 L67.6530612,22.6176618 L64.0755664,22.6176618 L64.0755664,21.1505355 C63.1735308,22.1882242 61.9330033,22.8709016 60.1312193,22.8709016 C57.6730353,22.8709016 55.6530612,21.4032997 55.6530612,18.7203845 L55.6530612,18.6697365 C55.6530612,15.7086142 57.8178093,14.3418326 60.909751,14.3418326 C62.2262106,14.3418326 63.1735308,14.5698673 64.1000384,14.8989601 L64.1000384,14.6709254 C64.1000384,13.0761091 63.1495162,12.1910776 61.3001604,12.1910776 L57.6003053,12.1910776 L57.6003053,8.87090164 L61.8358012,8.90062462 Z" />
        <path d="M73.0702807,26.8709016 L69.2255721,26.8709016 L69.7181813,23.6181665 L72.5920491,23.6256663 C73.2442259,23.6256663 73.596359,23.4200267 73.9218582,22.7249647 L68.6530612,8.87090164 L72.6931636,8.87090164 L75.7527616,18.2696006 L78.6881466,8.87090164 L82.6530612,8.87090164 L77.4846717,23.0085055 C76.4560849,25.8139139 75.3513676,26.8709016 73.0702807,26.8709016" />
        <path d="M110.772649,15.8713731 C110.772649,13.888751 109.299303,12.1579053 107.126213,12.1579053 C104.876347,12.1579053 103.53153,13.8380656 103.53153,15.8206878 L103.53153,15.8713731 C103.53153,17.8530523 105.006091,19.583898 107.177479,19.583898 C109.427832,19.583898 110.772649,17.9037377 110.772649,15.920644 L110.772649,15.8713731 Z M107.126213,22.8709016 C102.835918,22.8709016 99.6530612,19.7852249 99.6530612,15.920644 L99.6530612,15.8713731 C99.6530612,12.0067923 102.858756,8.87090164 107.177479,8.87090164 C111.472149,8.87090164 114.653061,11.9568141 114.653061,15.8206878 L114.653061,15.8713731 C114.653061,19.735011 111.447366,22.8709016 107.126213,22.8709016 L107.126213,22.8709016 Z" />
        <path d="M93.9371127,22.8709016 C89.73237,22.8709016 86.6530612,19.7598167 86.6530612,15.920761 L86.6530612,15.871491 C86.6530612,12.0317281 89.7095621,8.87090164 93.9898406,8.87090164 C96.6247636,8.87090164 98.2696285,9.72334409 99.5738468,11.1283654 L97.1473828,13.6378372 C96.2593471,12.7344745 95.3722923,12.1580857 93.9628635,12.1580857 C91.9785781,12.1580857 90.5693946,13.8382177 90.5693946,15.8208065 L90.5693946,15.871491 C90.5693946,17.9281027 91.953563,19.5839533 94.1200662,19.5839533 C95.451752,19.5839533 96.3638219,19.031846 97.3293554,18.1537076 L99.6530612,20.4125858 C98.2953794,21.84236 96.7312004,22.8709016 93.9371127,22.8709016" />
        <path d="M133.750976,22.8709016 L133.750976,15.2039195 C133.750976,13.3580039 132.92984,12.408786 131.467282,12.408786 C130.002553,12.408786 129.103501,13.3580039 129.103501,15.2039195 L129.103501,22.8709016 L125.201174,22.8709016 L125.201174,15.2039195 C125.201174,13.3580039 124.379797,12.408786 122.916515,12.408786 C121.45444,12.408786 120.554664,13.3580039 120.554664,15.2039195 L120.554664,22.8709016 L116.653061,22.8709016 L116.653061,9.12627498 L120.554664,9.12627498 L120.554664,11.0753036 C121.45444,9.9215461 122.635245,8.87090164 124.611134,8.87090164 C126.408997,8.87090164 127.769758,9.66520909 128.48789,11.0500071 C129.694989,9.63967175 131.133665,8.87090164 133.00848,8.87090164 C135.908268,8.87090164 137.653061,10.614186 137.653061,13.9217526 L137.653061,22.8709016 L133.750976,22.8709016 Z" />
        <path d="M168.93977,22.6145823 L168.93977,20.6661696 C168.082857,21.8193659 166.983473,22.8709016 165.102719,22.8709016 C162.291345,22.8709016 160.653061,20.9217663 160.653061,17.7693272 L160.653061,8.87090164 L164.369567,8.87090164 L164.369567,16.5378379 C164.369567,18.3838673 165.199156,19.3322971 166.617242,19.3322971 C168.034409,19.3322971 168.93977,18.3838673 168.93977,16.5378379 L168.93977,8.87090164 L172.653061,8.87090164 L172.653061,22.6145823 L168.93977,22.6145823 Z" />
      </g>
      <path
        d="M15.6530612,22.9046919 C15.2960402,24.7153626 14.9392674,26.5680487 14.5822464,28.3798309 C14.5301084,28.647041 14.2349081,28.8709016 13.9342457,28.8709016 L10.3704904,28.8709016 L9.77264158,28.8709016 L8.15586756,28.8709016 L8.15586756,28.8686786 C7.20372868,28.8686786 6.93633531,28.7853144 6.76427996,28.6510424 C6.59470738,28.5174374 6.63095569,28.2724578 6.79258344,27.9165482 C6.79258344,27.9165482 7.97810186,25.2644548 8.65018529,24.1020243 C9.32226872,22.9393714 10.6207528,22.8709016 11.4457743,22.8709016 C12.2705475,22.8709016 15.6009232,22.9046919 15.6009232,22.9046919 L15.6530612,22.9046919 Z"
        fill="#6cb3d7"
      />
      <g fill="#fff">
        <path d="M11.7605456,17.1919444 L11.7633282,17.1933217 C12.3233428,16.1653881 13.1064357,15.8371288 13.9050652,15.7425534 L18.9009055,15.7425534 C20.2245342,15.7425534 21.2977216,14.6804166 21.2977216,13.3703636 C21.2977216,12.0593925 20.2245342,10.9970261 18.9009055,10.9970261 L17.1679908,10.9970261 L16.2378796,10.9970261 L16.2378796,5.87090164 L16.6114546,5.87090164 L18.4681986,5.87090164 L18.6212461,5.87090164 C21.3700713,5.87090164 24.3350182,6.02929248 26.1001656,8.01767141 C28.0774925,10.2473094 28.209438,14.050526 26.2485753,17.0259783 C24.0393583,20.3840937 20.3066233,21.7276612 16.7248488,21.7999701 L15.6321827,21.7999701 L15.4051623,21.7999701 C15.1844029,21.7985928 14.9597014,21.7985928 14.719927,21.7999701 L14.6918683,21.7999701 L10.4042204,21.8709016 C10.4042204,21.8709016 9.19143513,21.828205 9.847916,20.6418805 C10.504165,19.4555561 11.7605456,17.1919444 11.7605456,17.1919444" />
        <path d="M17.2024317,5.87090164 L17.08712,5.87090164 L16.7342709,5.87090164 L16.6920476,5.87090164 L16.3626559,5.87090164 L16.0996858,5.87090164 L14.9231108,5.87090164 L14.4719875,5.87090164 L14.3305021,5.87090164 L14.0983971,5.87090164 L13.984073,5.87090164 L13.2334354,5.87090164 L13.1351611,5.87090164 L12.6413206,5.87090164 L11.2336282,5.87090164 C11.0242398,5.87090164 10.819296,5.9864361 10.6973174,6.14563184 C10.5383007,6.50432603 10.8294197,6.86100508 11.2257267,7.52017843 C11.9924142,8.79844631 12.1440232,9.09243225 12.7924358,10.0225294 C13.2882517,10.6008734 13.8606129,10.8261208 14.8873073,10.8709016 L15.1853401,10.8709016 L15.5680665,10.8709016 L17.6530612,10.8709016 L17.6530612,5.87090164 L17.2024317,5.87090164 Z" />
      </g>
    </svg>
  )
}
