import type { Accounts } from '~/db/types/public/Accounts'
import type { Roles } from '~/db/types/public/Roles'
import type { BasicAccountWithRoles } from '~/models/accounts.server'

export function hasRole(
  account: BasicAccountWithRoles | null | undefined,
  role: Roles['role'],
): boolean {
  return account?.roles?.some((r) => r.role === role) ?? false
}

export function hasActiveSubscription(
  account: BasicAccountWithRoles | Accounts | null | undefined,
): boolean {
  return Boolean(
    account?.subscription_expiry &&
      new Date(account?.subscription_expiry).getTime() > new Date().getTime(),
  )
}

export function isVerified(
  account: BasicAccountWithRoles | null | undefined,
): boolean {
  return Boolean(account && account?.roles.length > 0)
}
