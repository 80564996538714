import type { LinksFunction, LoaderFunctionArgs } from '@remix-run/node'
import { blockTrackingForMe, load, trackPageview } from 'fathom-client'
import NProgress from 'nprogress'
import { useEffect, useRef } from 'react'
import {
  ExternalScripts,
  type ExternalScriptsHandle,
} from 'remix-utils/external-scripts'
import { getClientLocales } from 'remix-utils/locales/server'
import * as accountUtils from '~/utils/accounts'

import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useNavigation,
} from '@remix-run/react'
import { withSentry } from '@sentry/remix'

import Footer from './components/Footer'

import * as flags from './config/flags'
import { GenericErrorBoundary } from './errors/error-boundary'
import { getAuthenticator } from './models/accounts.server'
import { getTenantByRequest } from './models/tenants/tenants.server'
import {
  canUnhideAffiliateOffers,
  shouldHideAffiliateOffers,
} from './sessions.server'
import globalStyle from './styles/globals.css?url'
import loaderStyle from './styles/loader.css?url'
import { badRequest } from './utils/responses.server'

export const meta = () => [{ title: 'LoungePair' }]

export const links: LinksFunction = () => [
  {
    rel: 'icon',
    type: 'image/png',
    href: '/favicon.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    href: '/favicon.ico',
  },
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  {
    rel: 'preconnect',
    href: 'https://fonts.gstatic.com',
    crossOrigin: 'anonymous',
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap',
  },
  {
    rel: 'stylesheet',
    href: loaderStyle,
  },
  {
    rel: 'stylesheet',
    href: globalStyle,
  },
]

export const handle: ExternalScriptsHandle = {
  scripts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/@twemoji/api@15.1.0/dist/twemoji.min.js',
      integrity:
        'sha384-o28+zJO3/45GHIy+9TFKGaYnbt0KQcFRzyBrb0WSSrz7bPGwGI1d64worBiXPgXw',
      crossOrigin: 'anonymous',
    },
  ],
}

export async function loader({ request }: LoaderFunctionArgs) {
  const locales = getClientLocales(request)
  const isCloudflareHealthCheck = request.headers.has('x-healthcheck')
  const [tenant, hideAffiliateOffers, canToggleAffiliateOffers] =
    await Promise.all([
      getTenantByRequest(request),
      shouldHideAffiliateOffers(request),
      canUnhideAffiliateOffers(request),
    ])
  if (tenant instanceof Error) {
    throw badRequest(tenant.message)
  }
  const authenticator = await getAuthenticator(request)
  if (authenticator instanceof Error) throw authenticator
  const account = await authenticator.isAuthenticated(request)
  const hasActiveSubscription = accountUtils.hasActiveSubscription(account)

  await tenant.populatePromos()

  return {
    url: new URL(request.url),
    tenant: tenant.public,
    account,
    hasActiveSubscription,
    locales,
    isCloudflareHealthCheck,
    hideAffiliateOffers,
    canToggleAffiliateOffers,
    ...flags,
  }
}

export default withSentry(function App() {
  const { isCloudflareHealthCheck } = useLoaderData<typeof loader>()
  const { state } = useNavigation()

  const fathomLoaded = useRef(false)
  const location = useLocation()

  useEffect(
    function setupFathom() {
      if (isCloudflareHealthCheck) {
        return blockTrackingForMe()
      }
      if (!fathomLoaded.current) {
        load('QAWOREDB', {
          excludedDomains: ['staging*.loungepair.com', '*.localhost'],
        })
        fathomLoaded.current = true
      } else {
        trackPageview()
      }
    },
    [location],
  )

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (state === 'idle')
        // when the state is idle then we can to complete the progress bar
        NProgress.done()
      // and when it's something else it means it's either submitting a form or
      // waiting for the loaders of the next location so we start it
      else NProgress.start()
    }
  }, [state])

  return <Outlet />
})

export function Layout({ children }: { children: React.ReactNode }) {
  // const matches = useMatches()
  // const showBanner = matches.some(
  //   (match) => match.id === 'routes/t+/$id+/vouchers/_order-vouchers',
  // )

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,user-scalable=no"
        />
        <Meta />
        <Links />
      </head>
      <body>
        <svg className="hidden">
          <defs>
            <path
              id="BuyOnLoungePair"
              d="M6.20553 0C2.78482 0 0 2.82222 0 6.28889C0 7.28889 0.219277 8.22222 0.635903 9.06667L0.701686 9.2L6.20553 20L11.6655 9.28889L11.6875 9.22222C12.1479 8.35556 12.4111 7.35556 12.4111 6.28889C12.4111 2.82222 9.64818 0 6.20553 0ZM9.1877 7.6L6.5783 6.57778V7.51111C6.5783 7.6 6.55638 7.68889 6.51252 7.75556L7.43348 8.37778V8.88889L6.18361 8.37778L4.93373 8.88889V8.37778L5.85469 7.75556C5.81084 7.68889 5.78891 7.6 5.78891 7.51111V6.57778L3.17951 7.57778V6.95556L5.83276 4.88889V3.51111C5.83276 3.26667 5.92047 3 6.09589 2.82222C6.11782 2.77778 6.16168 2.77778 6.20553 2.77778C6.24939 2.77778 6.29324 2.8 6.31517 2.82222C6.46867 3.02222 6.5783 3.26667 6.5783 3.51111V4.88889L9.1877 6.95556V7.6Z"
            />
          </defs>
        </svg>
        {children}
        <Footer />

        <ScrollRestoration />
        <Scripts />
        <ExternalScripts />
      </body>
    </html>
  )
}

export function ErrorBoundary() {
  return (
    <GenericErrorBoundary
      statusHandlers={{
        403: ({ error }) => <p>You are not allowed to do that!</p>,
      }}
    />
  )
}
