import type { ComponentPropsWithRef } from 'react'

export default function AirportIcon(
  props: ComponentPropsWithRef<'svg'> & {
    alt?: string
  },
) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M9.14393 2.9375C9.14393 2.41797 9.56189 2 10.0814 2H11.6439H13.2064C13.726 2 14.1439 2.41797 14.1439 2.9375C14.1439 3.45703 13.726 3.875 13.2064 3.875H12.5814V5.75H14.1439C14.8353 5.75 15.3939 6.30859 15.3939 7H19.0345C19.8705 7 20.472 7.80859 20.2299 8.60938L18.6244 13.9727C18.933 14.125 19.1439 14.4453 19.1439 14.8125C19.1439 15.332 18.726 15.75 18.2064 15.75H17.2689V21.0625C17.2689 21.582 16.851 22 16.3314 22C15.8119 22 15.3939 21.582 15.3939 21.0625V15.75H7.89393V21.0625C7.89393 21.582 7.47596 22 6.95643 22C6.43689 22 6.01893 21.582 6.01893 21.0625V15.75H5.08143C4.56189 15.75 4.14393 15.332 4.14393 14.8125C4.14393 14.4453 4.35486 14.125 4.66346 13.9727L3.05408 8.60938C2.81189 7.80859 3.41346 7 4.2533 7H7.89393C7.89393 6.30859 8.45252 5.75 9.14393 5.75H10.7064V3.875H10.0814C9.56189 3.875 9.14393 3.45703 9.14393 2.9375ZM6.59314 13.875H7.89393V8.875H5.09314L6.59314 13.875ZM9.76893 13.875H13.5189V8.875H9.76893V13.875ZM15.3939 8.875V13.875H16.6947L18.1947 8.875H15.3939Z"
        fill="#374D63"
      />
    </svg>
  )
}
